import React from 'react';
import { Table } from 'antd';
import { useIntl } from 'react-intl';
import { useStoreState } from '../store/hooks';
import { CountryDetail } from '../api/types';
import UpdateButton from './UpdateButton';

export interface Props {
  onView: (id: number) => void;
  onEdit: (id: number) => void;
}

const CountryTable: React.FC<Props> = ({ onEdit, onView }) => {
  const { listDetail: data, isLoading } = useStoreState(
    state => state.countries,
  );

  const { formatMessage } = useIntl();

  return (
    <Table<CountryDetail>
      loading={isLoading}
      dataSource={data.valueSeq().toArray()}
      size='middle'
      rowKey='id'
      rowClassName={() => 'c-pointer'}
      onRow={({ id }) => ({
        onClick: e => {
          e.preventDefault();
          onView(id);
        },
      })}
      columns={[
        {
          dataIndex: 'id',
          title: formatMessage({
            id: 'countries.table.titles.id',
          }),
        },
        {
          dataIndex: 'code',
          title: formatMessage({
            id: 'countries.table.titles.code',
          }),
        },
        {
          dataIndex: 'calendar.name',
          title: formatMessage({
            id: 'warehouses.table.titles.calendar',
          }),
        },
        {
          key: 'update-button',
          align: 'right',
          render: (text, { id }) => (
            <UpdateButton
              onClick={e => {
                e.preventDefault();
                onEdit(id);
              }}
            />
          ),
        },
      ]}
    />
  );
};

export default CountryTable;
