import { useState, useCallback } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';

export default function useFormRef<T>() {
  const [formRef, setFormRef] = useState<WrappedFormUtils<T>>();

  const saveFormRef = useCallback(node => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  return { formRef, saveFormRef };
}
