import React, { useEffect } from 'react';
import { Form, Input, Switch, Select, InputNumber } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import MaskedInput from 'antd-mask-input';
import { FormattedMessage } from 'react-intl';
import { WarehouseIn, allDays, Warehouse } from '../api/types';
import { useStoreState, useStoreActions } from '../store/hooks';
import DaysPicker from './DaysPicker';
import { Mode } from '../store/types';
import { transformFormData } from '../utils/DataManagement';

interface Props extends FormComponentProps<WarehouseIn> {
  data: Warehouse;
  mode: Mode;
}

const WarehouseForm: React.FC<Props> = ({
  form: { getFieldDecorator, getFieldValue },
  mode,
}) => {
  const isView = mode === Mode.VIEW;
  const countries = useStoreState(state => state.countries.list)
    .valueSeq()
    .toArray();
  const calendars = useStoreState(state => state.calendars.list);
  const fetchCountries = useStoreActions(
    actions => actions.countries.fetchList,
  );
  const fetchCalendars = useStoreActions(
    actions => actions.calendars.fetchList,
  );

  useEffect(() => {
    fetchCountries();
    fetchCalendars({});
  }, [fetchCalendars, fetchCountries]);

  getFieldDecorator('id');
  return (
    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
      <Form.Item label={<FormattedMessage id='warehouses.detail.active' />}>
        {getFieldDecorator('active', {
          valuePropName: 'checked',
        })(<Switch disabled={isView} />)}
      </Form.Item>
      <Form.Item label={<FormattedMessage id='warehouses.detail.name' />}>
        {getFieldDecorator('name', {
          rules: [
            {
              required: true,
              message: <FormattedMessage id='validations.required' />,
            },
          ],
        })(<Input disabled={isView} />)}
      </Form.Item>
      <Form.Item label={<FormattedMessage id='warehouses.detail.country' />}>
        {getFieldDecorator('country.id', {
          rules: [
            {
              required: true,
              message: <FormattedMessage id='validations.required' />,
            },
          ],
        })(
          <Select disabled={isView}>
            {countries.map(({ code, id }) => (
              <Select.Option key={id} value={id}>
                {code}
              </Select.Option>
            ))}
          </Select>,
        )}
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id='warehouses.detail.operation_days' />}
      >
        {getFieldDecorator('operationDays')(<DaysPicker disabled={isView} />)}
      </Form.Item>
      <Form.Item label={<FormattedMessage id='warehouses.detail.calendar' />}>
        {getFieldDecorator('calendar.id', {
          rules: [
            {
              required: true,
              message: <FormattedMessage id='validations.required' />,
            },
          ],
        })(
          <Select disabled={isView}>
            {calendars
              .valueSeq()
              .toArray()
              .map(({ name, id }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
          </Select>,
        )}
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id='warehouses.detail.stocking_delay' />}
      >
        {getFieldDecorator('stockingDelay')(<InputNumber disabled={isView} />)}
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id='warehouses.detail.expedition_delay' />}
      >
        {getFieldDecorator('expeditionDelay')(
          <InputNumber disabled={isView} />,
        )}
      </Form.Item>
      {getFieldValue('heliosSupply') && (
        <Form.Item
          label={<FormattedMessage id='warehouses.detail.stock_close_time' />}
          wrapperCol={{ span: 3 }}
        >
          {getFieldDecorator('stockCloseTime', {
            rules: [
              {
                required: true,
                message: <FormattedMessage id='validations.required' />,
              },
            ],
          })(<MaskedInput mask='11:11' disabled={isView} />)}
        </Form.Item>
      )}
    </Form>
  );
};

export default Form.create<Props>({
  mapPropsToFields: ({ data }) =>
    transformFormData({
      ...data,
      operationDays: allDays.map(v => ({
        day: v,
        checked: data.operationDays.includes(v),
      })),
    }),
})(WarehouseForm);
