import { AxiosPromise, AxiosBasicCredentials } from 'axios';
import http from './httpBase';
import { UserResponse } from './types';
import { userData } from './mockData';

const { get, mock } = http;
const USER_URL = '/user';

const getLoggedUser = (
  auth: AxiosBasicCredentials,
): AxiosPromise<UserResponse> => get(`${USER_URL}`, { auth });

mock.onGet(USER_URL).reply(200, userData);

export default {
  getLoggedUser,
};
