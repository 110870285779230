import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, message } from 'antd';
import TransportDefinitionsTable from '../components/TransportDefinitionsTable';
import NewButton from '../components/NewButton';
import WarehouseSelect from '../components/WarehouseSelect';
import useFormRef from '../hooks/useFormRef';
import { Mode } from '../store/types';
import { useStoreActions, useStoreState } from '../store/hooks';
import { WarehouseTransportIn } from '../api/types';
import { defaultTD } from '../store/transportDefinitions';
import TDForm from '../components/TDForm';

const TransportDefinitionsPage: React.FC = () => {
  const [isVisible, setVisible] = useState(false);
  const [mode, setMode] = useState<Mode>(Mode.VIEW);
  const { saveFormRef, formRef } = useFormRef<WarehouseTransportIn>();
  const { setDetail, fetchTD, createTD, updateTD } = useStoreActions(
    actions => actions.transportDefinitions,
  );
  const { detail } = useStoreState(state => state.transportDefinitions);

  const { formatMessage } = useIntl();

  const handleEdit = async (id: number) => {
    await fetchTD(id);
    setMode(Mode.EDIT);
    setVisible(true);
  };

  const handleView = async (id: number) => {
    await fetchTD(id);
    setMode(Mode.VIEW);
    setVisible(true);
  };

  const handleNew = () => {
    setDetail(defaultTD);
    setMode(Mode.CREATE);
    setVisible(true);
  };

  const handleSave = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    formRef!.validateFields(
      (err, { deliveryDays, expeditionDays, zips, ...rest }) => {
        if (!err) {
          const inValues = {
            ...rest,
            deliveryDays: deliveryDays.filter(v => v.checked).map(v => v.day),
            expeditionDays: expeditionDays
              .filter(v => v.checked)
              .map(({ day, closeTime }) => ({ day, closeTime })),
            zips: zips.map(zip => {
              if (zip.expeditionDays !== undefined) {
                const newExpeditionDays = zip.expeditionDays.filter(
                  expDay =>
                    expeditionDays[parseInt(expDay.day, 10) - 1].checked,
                );
                zip.expeditionDays = newExpeditionDays;
              }
              return zip;
            }),
          };
          switch (mode) {
            case Mode.CREATE:
              createTD(inValues).then(res => {
                if (res) {
                  message.success(
                    formatMessage({ id: 'common.message.success_new' }),
                  );
                  setVisible(false);
                }
              });

              break;
            case Mode.EDIT:
              updateTD(inValues).then(res => {
                if (res) {
                  message.success(
                    formatMessage({ id: 'common.message.success_edit' }),
                  );
                  setVisible(false);
                }
              });

              break;
            default:
              break;
          }
        }
      },
    );
  };

  return (
    <div className='transport-definitions'>
      <WarehouseSelect />
      <NewButton onClick={handleNew} />
      <TransportDefinitionsTable onEdit={handleEdit} onView={handleView} />
      <Modal
        title={<FormattedMessage id='transport_definitions.detail.title' />}
        visible={isVisible}
        width={1200}
        footer={mode === Mode.VIEW ? null : undefined}
        onCancel={e => {
          e.preventDefault();
          setVisible(false);
          formRef!.resetFields();
        }}
        okText={<FormattedMessage id='common.save' />}
        onOk={handleSave}
      >
        <TDForm ref={saveFormRef} data={detail} mode={mode} />
      </Modal>
    </div>
  );
};
export default TransportDefinitionsPage;
