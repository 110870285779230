import { Row, Form, Switch, Col } from 'antd';
import React, { forwardRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import TimesPicker from './TimesPicker';
import { DayCheckTime, ExpeditionDay } from '../api/types';

export interface Props {
  value?: DayCheckTime[];
  zipCloseTimes?: ExpeditionDay[];
  onChange?: Function;
  disabled?: boolean;
}

const ZipCloseTimeInput = forwardRef<HTMLDivElement, Props>(
  ({ value, zipCloseTimes, onChange, disabled }, ref) => {
    const [customCloseTime, setCustomCloseTime] = useState(
      zipCloseTimes !== undefined,
    );

    const handleChange = (val: DayCheckTime[] | undefined) => {
      if (onChange) {
        if (value && value.length > 0) {
          if (val === undefined) {
            const valueClone = val;
            onChange(valueClone);
          } else {
            const valueClone = val
              .filter(day => day.checked)
              .map(({ day, closeTime }) => ({ day, closeTime }));
            onChange(valueClone);
          }
        }
      }
    };

    return (
      <div ref={ref}>
        <Row type='flex' align='middle' justify='start'>
          <Col offset={2} span={3}>
            <Form.Item
              style={{ width: '500px', position: 'relative', left: '-77px' }}
              label={
                <FormattedMessage id='transport_definitions.detail.zip_range.enable_close_time' />
              }
            >
              <Switch
                disabled={disabled}
                checked={customCloseTime}
                onChange={change => {
                  setCustomCloseTime(change);
                  if (customCloseTime) {
                    handleChange(undefined);
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={19}>
            {customCloseTime && (
              <Form.Item
                style={{ position: 'relative', left: '-36px' }}
                label={
                  <FormattedMessage id='transport_definitions.detail.zip_range.close_time' />
                }
              >
                <TimesPicker
                  value={value}
                  disabled={disabled}
                  onChange={(val: DayCheckTime[]) => handleChange(val)}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
      </div>
    );
  },
);

export default ZipCloseTimeInput;
