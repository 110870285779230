import React, { useEffect } from 'react';
import { Form, Select, Row, Col, Tabs, Switch } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { FormattedMessage } from 'react-intl';
import {
  WarehouseTransport,
  allDays,
  WarehouseTransportIn,
  Zip,
} from '../api/types';
import { useStoreState, useStoreActions } from '../store/hooks';
import DaysPicker from './DaysPicker';
import { Mode } from '../store/types';
import { transformFormData } from '../utils/DataManagement';
import TimesPicker from './TimesPicker';
import NewButton from './NewButton';
import ZipInputs from './ZipInputs';
import PuPSelect from './PuPSelect';

interface Props extends FormComponentProps<WarehouseTransportIn> {
  data: WarehouseTransport;
  mode: Mode;
}

const TDForm: React.FC<Props> = ({
  form: { getFieldDecorator, getFieldValue, setFieldsValue },
  mode,
}) => {
  const isView = mode === Mode.VIEW;
  const calendars = useStoreState(state => state.calendars.list);
  const warehouses = useStoreState(state => state.warehouses.list);
  const transports = useStoreState(state => state.carriers.list);
  const countries = useStoreState(state => state.countries.list);

  const fetchCalendars = useStoreActions(
    actions => actions.calendars.fetchList,
  );
  const fetchTransports = useStoreActions(
    actions => actions.carriers.fetchList,
  );
  const fetchWarehouses = useStoreActions(
    actions => actions.warehouses.fetchList,
  );
  const fetchCountries = useStoreActions(
    actions => actions.countries.fetchList,
  );

  const zips: Zip[] = getFieldValue('zips');
  const addZip = () => {
    const newId = zips.length === 0 ? 0 : zips[zips.length - 1].id + 1;
    setFieldsValue({
      zips: [...zips, { id: newId }],
    });
  };

  const removeZipExpeditionDays = () => {
    const newZips = zips.map(zip => {
      const { expeditionDays, ...zipClone } = zip;
      return zipClone;
    });
    setFieldsValue({ zips: [...newZips] });
  };

  useEffect(() => {
    fetchCalendars({});
    fetchTransports();
    fetchWarehouses();
    fetchCountries();
  }, [fetchCalendars, fetchTransports, fetchWarehouses, fetchCountries]);

  getFieldDecorator('id');
  getFieldDecorator('pickupPoints');
  getFieldDecorator('usesCustomCloseTimes', {
    initialValue: zips.some(zip => zip.expeditionDays !== undefined),
  });
  return (
    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} className='pup-form'>
      <Row gutter={16} type='flex' justify='space-around' align='middle'>
        <Col span={12}>
          <Form.Item
            label={
              <FormattedMessage id='transport_definitions.detail.warehouse' />
            }
          >
            {getFieldDecorator('warehouse.id', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='validations.required' />,
                },
              ],
            })(
              <Select disabled={isView}>
                {warehouses
                  .valueSeq()
                  .toArray()
                  .map(({ name, id }) => (
                    <Select.Option key={id} value={id}>
                      {name}
                    </Select.Option>
                  ))}
              </Select>,
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            style={{ marginLeft: '26px' }}
            label={
              <FormattedMessage id='transport_definitions.detail.transport' />
            }
          >
            {getFieldDecorator('transport.id', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='validations.required' />,
                },
              ],
            })(
              <Select disabled={isView}>
                {transports
                  .valueSeq()
                  .toArray()
                  .map(({ name, id }) => (
                    <Select.Option key={id} value={id}>
                      {name}
                    </Select.Option>
                  ))}
              </Select>,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={
              <FormattedMessage id='transport_definitions.detail.country' />
            }
          >
            {getFieldDecorator('country.id', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='validations.required' />,
                },
              ],
            })(
              <Select disabled={isView}>
                {countries
                  .valueSeq()
                  .toArray()
                  .map(({ code, id }) => (
                    <Select.Option key={id} value={id}>
                      {code}
                    </Select.Option>
                  ))}
              </Select>,
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            style={{ width: '650px' }}
            label={
              <FormattedMessage id='transport_definitions.detail.custom_close_times' />
            }
          >
            {getFieldDecorator('usesCustomCloseTimes', {
              valuePropName: 'checked',
            })(
              <Switch
                disabled={isView}
                onChange={value => {
                  setFieldsValue({ usesCustomCloseTime: value });
                  if (!value) {
                    removeZipExpeditionDays();
                  }
                }}
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row type='flex' justify='space-around' align='middle'>
        <Tabs defaultActiveKey='1'>
          <Tabs.TabPane
            tab={
              <FormattedMessage id='transport_definitions.detail.operational_parameters.title' />
            }
            key='1'
          >
            <Form.Item
              label={
                <FormattedMessage id='transport_definitions.detail.operational_parameters.transport_calendar' />
              }
            >
              {getFieldDecorator('calendar.id', {
                rules: [
                  {
                    required: true,
                    message: <FormattedMessage id='validations.required' />,
                  },
                ],
              })(
                <Select disabled={isView}>
                  {calendars
                    .valueSeq()
                    .toArray()
                    .map(({ name, id }) => (
                      <Select.Option key={id} value={id}>
                        {name}
                      </Select.Option>
                    ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item
              label={
                <FormattedMessage id='transport_definitions.detail.operational_parameters.delivery_days' />
              }
            >
              {getFieldDecorator('deliveryDays')(
                <DaysPicker disabled={isView} />,
              )}
            </Form.Item>
            <Form.Item
              label={
                <FormattedMessage id='transport_definitions.detail.operational_parameters.expedition_days' />
              }
            >
              {getFieldDecorator('expeditionDays')(
                <DaysPicker disabled={isView} />,
              )}
            </Form.Item>
            <Form.Item
              label={
                <FormattedMessage id='transport_definitions.detail.operational_parameters.close_time' />
              }
            >
              {getFieldDecorator('expeditionDays')(
                <TimesPicker disabled={isView} />,
              )}
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <FormattedMessage id='transport_definitions.detail.zip_range.title' />
            }
            key='2'
          >
            {!isView && <NewButton onClick={addZip} />}
            {getFieldDecorator('zips')(
              <ZipInputs
                usesCustomCloseTimes={getFieldValue('usesCustomCloseTimes')}
                disabled={isView}
                warehouseTransportExpeditionDays={getFieldValue(
                  'expeditionDays',
                )}
              />,
            )}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <FormattedMessage id='transport_definitions.detail.pups.title' />
            }
            key='3'
          >
            {getFieldDecorator('pickupPoints')(<PuPSelect disabled={isView} />)}
          </Tabs.TabPane>
        </Tabs>
      </Row>
    </Form>
  );
};

export default Form.create<Props>({
  mapPropsToFields: ({ data: { expeditionDays, deliveryDays, ...rest } }) =>
    transformFormData({
      expeditionDays: allDays.map(v => {
        const index = expeditionDays.findIndex(val => val.day === v);
        const isChecked = index !== -1;
        return {
          checked: isChecked,
          day: v,
          closeTime: isChecked ? expeditionDays[index].closeTime : 'x',
        };
      }),
      deliveryDays: allDays.map(v => ({
        day: v,
        checked: deliveryDays.includes(v),
      })),
      ...rest,
    }),
})(TDForm);
