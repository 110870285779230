import React, { useState } from 'react';
import { Modal, message } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import NewButton from '../components/NewButton';
import PuPSearchButton from '../components/PuPSearchButton';
import PuPTable from '../components/PuPTable';
import { Mode } from '../store/types';
import { PickupPointIn } from '../api/types';
import useFormRef from '../hooks/useFormRef';
import { useStoreState, useStoreActions } from '../store/hooks';
import { defaultPuP } from '../store/pickupPoints';
import PuPForm from '../components/PuPForm';

const PickupPointsPage: React.FC = () => {
  const [isVisible, setVisible] = useState(false);
  const [mode, setMode] = useState<Mode>(Mode.VIEW);
  const { saveFormRef, formRef } = useFormRef<PickupPointIn>();
  const { setDetail, fetchPuP, createPuP, updatePuP } = useStoreActions(
    actions => actions.pickupPoints,
  );
  const { detail } = useStoreState(state => state.pickupPoints);

  const { formatMessage } = useIntl();

  const handleEdit = async (id: number) => {
    await fetchPuP(id);
    setMode(Mode.EDIT);
    setVisible(true);
  };

  const handleView = async (id: number) => {
    await fetchPuP(id);
    setMode(Mode.VIEW);
    setVisible(true);
  };

  const handleNew = () => {
    setDetail(defaultPuP);
    setMode(Mode.CREATE);
    setVisible(true);
  };

  const handleSave = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    formRef!.validateFields((err, { warehouseOpenDays, openDays, ...rest }) => {
      if (!err) {
        const inValues = {
          ...rest,
          warehouseOpenDays: warehouseOpenDays
            .filter(v => v.checked)
            .map(v => v.day),
          openDays: openDays
            .filter(v => v.checked)
            .map(({ day, closeTime, openTime }) => ({
              day,
              closeTime,
              openTime,
            })),
        };
        switch (mode) {
          case Mode.CREATE:
            createPuP(inValues).then(res => {
              if (res) {
                message.success(
                  formatMessage({ id: 'common.message.success_new' }),
                );
                setVisible(false);
              }
            });
            break;
          case Mode.EDIT:
            updatePuP(inValues).then(res => {
              if (res) {
                message.success(
                  formatMessage({ id: 'common.message.success_edit' }),
                );
                setVisible(false);
              }
            });
            break;
          default:
            break;
        }
      }
    });
  };

  return (
    <div className='pup-definitions'>
      <PuPSearchButton className='search-input' />
      <NewButton onClick={handleNew} />
      <PuPTable onEdit={handleEdit} onView={handleView} />
      <Modal
        title={<FormattedMessage id='pup_definitions.detail.title' />}
        visible={isVisible}
        footer={mode === Mode.VIEW ? null : undefined}
        onCancel={e => {
          e.preventDefault();
          setVisible(false);
          formRef!.resetFields();
        }}
        okText={<FormattedMessage id='common.save' />}
        onOk={handleSave}
        width={650}
      >
        <PuPForm ref={saveFormRef} data={detail} mode={mode} />
      </Modal>
    </div>
  );
};

export default PickupPointsPage;
