import React from 'react';
import { Table, Icon, Tooltip, Row } from 'antd';
import { useIntl } from 'react-intl';
import { useStoreState } from '../store/hooks';
import { flatDays } from '../utils/DataManagement';
import UpdateButton from './UpdateButton';
import { PickupPointListItem } from '../api/types';

export interface Props {
  onView: (id: number) => void;
  onEdit: (id: number) => void;
}

const PuPTable: React.FC<Props> = ({ onEdit, onView }) => {
  const { list: data, isLoading } = useStoreState(state => state.pickupPoints);

  const { formatMessage } = useIntl();

  return (
    <Table<PickupPointListItem>
      loading={isLoading}
      dataSource={data.valueSeq().toArray()}
      size='middle'
      rowKey='id'
      rowClassName={record =>
        `c-pointer${!record.active ? ' inactive-row' : ''}`
      }
      onRow={({ id }) => ({
        onClick: e => {
          e.preventDefault();
          onView(id);
        },
      })}
      columns={[
        {
          dataIndex: 'id',
          title: formatMessage({ id: 'pup_definitions.table.titles.id' }),
        },
        {
          dataIndex: 'name',
          title: formatMessage({
            id: 'pup_definitions.table.titles.name',
          }),
          render: (text, { name, active }) =>
            active
              ? name
              : `${name} <${formatMessage({ id: 'common.inactive' })}>`,
        },
        {
          dataIndex: 'country.code',
          title: formatMessage({
            id: 'pup_definitions.table.titles.country',
          }),
        },
        {
          dataIndex: 'openDays',
          key: 'openDaysDays',
          title: formatMessage({
            id: 'pup_definitions.table.titles.open',
          }),
          render: (text, { openDays }) => (
            <>
              {flatDays(
                openDays.map(v => v.day),
                formatMessage,
              )}
            </>
          ),
        },
        {
          dataIndex: 'openDays',
          key: 'openDaysCloseTimes',
          title: formatMessage({
            id: 'pup_definitions.table.titles.close_times',
          }),
          align: 'center',
          render: (text, { openDays }) => (
            <Tooltip
              title={
                <div>
                  {openDays.map(({ day, closeTime }) => (
                    <Row key={day}>
                      {`${formatMessage({
                        id: `enums.days.${day}`,
                      })}: ${closeTime}`}
                    </Row>
                  ))}
                </div>
              }
            >
              <Icon className='eye' type='eye' theme='twoTone' />
            </Tooltip>
          ),
        },
        {
          dataIndex: 'calendar.name',
          title: formatMessage({
            id: 'pup_definitions.table.titles.calendar',
          }),
        },
        {
          dataIndex: 'warehouseStockingDelay',
          title: formatMessage({
            id: 'pup_definitions.table.titles.stocking_delay',
          }),
          align: 'center',
        },
        {
          dataIndex: 'warehouseExpeditionDelay',
          title: formatMessage({
            id: 'pup_definitions.table.titles.expedition_delay',
          }),
          align: 'center',
        },
        {
          key: 'update-button',
          align: 'right',
          render: (text, { id }) => (
            <UpdateButton
              onClick={e => {
                e.preventDefault();
                onEdit(id);
              }}
            />
          ),
        },
      ]}
    />
  );
};

export default PuPTable;
