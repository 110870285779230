import React from 'react';
import { Table } from 'antd';
import { useIntl } from 'react-intl';
import { useStoreState } from '../store/hooks';
import UpdateButton from './UpdateButton';
import { CalendarListItem } from '../api/types';

export interface Props {
  onView: (id: number) => void;
  onEdit: (id: number) => void;
}

const CalendarTable: React.FC<Props> = ({ onEdit, onView }) => {
  const { list: data, isLoading } = useStoreState(state => state.calendars);

  const { formatMessage } = useIntl();

  return (
    <Table<CalendarListItem>
      loading={isLoading}
      dataSource={data.valueSeq().toArray()}
      size='middle'
      rowKey='id'
      onRow={({ id }) => ({
        onClick: e => {
          e.preventDefault();
          onView(id);
        },
      })}
      rowClassName={() => 'c-pointer'}
      columns={[
        {
          dataIndex: 'id',
          title: formatMessage({ id: 'calendars.table.titles.id' }),
        },
        {
          dataIndex: 'name',
          title: formatMessage({
            id: 'calendars.table.titles.name',
          }),
        },
        {
          key: 'update-button',
          align: 'right',
          render: (text, { id }) => (
            <UpdateButton
              onClick={e => {
                e.preventDefault();
                onEdit(id);
              }}
            />
          ),
        },
      ]}
    />
  );
};

export default CalendarTable;
