export default {
  enums: {
    days: {
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
      7: 'Sunday',
    },
  },
  side_bar: {
    data_management: 'Data management',
    calendars: 'Calendars',
    carriers: 'Carriers',
    countries: 'Countries',
    warehouses: 'Warehouses',
    transport_management: 'Transport management',
    transport_definitions: 'Transport definitions',
    pup_definitions: 'PuP definitions',
  },
  header: { logout: 'Log out' },
  common: {
    update: 'update',
    inactive: 'inactive',
    save: 'Save',
    message: {
      success_new: 'Record was successfully created.',
      success_edit: 'Record was successfully updated.',
      error: 'Error! Record was not saved.',
    },
  },
  login: {
    title: 'Log in',
    username: 'Username',
    password: 'Password',
    remember: 'Remember me',
    button: 'Log in',
    validations: {
      username: 'Please input your username!',
      password: 'Please input your password!',
    },
  },
  transport_definitions: {
    select_warehouse: 'Select warehouse',
    table: {
      titles: {
        id: 'ID',
        warehouse: 'Warehouse',
        transport: 'Transport',
        country: 'Country',
        delivery_days: 'Delivery days',
        expedition_days: 'Expedition days & close times',
        calendar: 'Calendar',
        zip_custom_close_times_defined: 'Zip custom close times defined',
      },
    },
    detail: {
      title: 'Transport definition detail',
      warehouse: 'Warehouse',
      transport: 'Transport',
      country: 'Country',
      custom_close_times: 'Custom zip close times',
      operational_parameters: {
        title: 'Operational parameters',
        transport_calendar: 'Transport calendar',
        delivery_days: 'Delivery days',
        expedition_days: 'Expedition days',
        close_time: 'Default close time',
      },
      zip_range: {
        title: 'Zip range',
        zip_from: 'Zip from',
        zip_to: 'Zip to',
        delivery_duration: 'Delivery duration',
        enable_close_time: 'Enable close time',
        close_time: 'Close time',
      },
      pups: {
        title: 'Pickup points',
        add: 'add PuP from the list',
        id: 'Id',
        name: 'Name',
      },
    },
  },
  pup_definitions: {
    search_placeholder: 'Search pick-up points',
    table: {
      titles: {
        id: 'ID',
        name: 'Name',
        country: 'Country',
        open: 'Open',
        close_times: 'Close times',
        calendar: 'Calendar',
        stocking_delay: 'Stocking delay',
        expedition_delay: 'Expedition delay',
      },
    },
    detail: {
      title: 'Pickup point detail',
      tab1: 'Pickup point operations',
      tab2: 'Pickup point warehouse',
      name: 'Name',
      active: 'Active',
      country: 'Country',
      postcode: 'Postcode',
      open_days: 'Pup days open',
      close_times: 'Close times',
      open_times: 'Open times',
      warehouseId: 'Warehouse ID',
      operation_days: 'Operation days',
      calendar: 'Calendar',
      stocking_delay: 'Stocking delay',
      expedition_delay: 'Expedition delay',
      minutes: '(minutes)',
    },
  },
  calendars: {
    select_country: 'Select countries',
    table: {
      titles: {
        id: 'ID',
        name: 'Name',
      },
    },
    detail: {
      title: 'Calendar detail',
      name: 'Calendar name',
      copy_dates: 'Copy dates from different year',
      entity: {
        button: 'List of entities using this calendar',
        title: 'Entities',
        entity: 'Entity',
        name: 'Name',
      },
    },
  },
  carriers: {
    table: {
      titles: {
        id: 'ID',
        name: 'Name',
        onlyPupDelivery: 'PuP Delivery Only',
      },
    },
    detail: {
      title: 'Carrier detail',
      name: 'Name',
      active: 'Active',
      onlyPupDelivery: 'PuP Only',
    },
  },
  countries: {
    table: {
      titles: {
        id: 'ID',
        code: 'Code',
      },
    },
    detail: {
      title: 'Country detail',
      code: 'Country code',
      calendar: 'Calendar',
    },
  },
  warehouses: {
    table: {
      titles: {
        id: 'ID',
        name: 'Name',
        country: 'Country',
        operation_days: 'Operation days',
        calendar: 'Calendar',
        stocking_delay: 'Stocking delay',
        expedition_delay: 'Expedition delay',
        stock_close_time: 'Stock close time',
      },
    },
    detail: {
      title: 'Warehouse detail',
      active: 'Active',
      name: 'Name',
      country: 'Country',
      operation_days: 'Operation days',
      calendar: 'Calendar',
      stocking_delay: 'Stocking delay',
      expedition_delay: 'Expedition delay',
      stock_close_time: 'Stock close time',
    },
  },
  logout: 'Logging out...',
  validations: {
    required: 'This field is required!',
  },
};
