import React, { useState, useEffect } from 'react';
import { Form, Input, Row, Button, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { CalendarDetail, CalendarIn } from '../api/types';
import { Mode } from '../store/types';
import { transformFormData } from '../utils/DataManagement';
import { YearCalendar } from './YearCalendar';
import EntityTable from './EntityTable';
import CopyDates from './CopyDates';

const defaultYear = new Date().getFullYear();

interface Props extends FormComponentProps<CalendarIn> {
  data: CalendarDetail;
  mode: Mode;
}

const CalendarForm: React.FC<Props> = ({
  form: { getFieldDecorator, setFieldsValue, getFieldValue },
  mode,
  data,
}) => {
  const [isVisible, setVisible] = useState(false);
  const [currentYear, setCurrentYear] = useState(defaultYear);
  const isView = mode === Mode.VIEW;
  getFieldDecorator('id');
  getFieldDecorator('days');
  const [days, setDays] = useState<string[]>(getFieldValue('days'));
  const uniqueYears = Array.from(
    new Set(days.map(v => moment(v).format('YYYY'))),
  ).sort((a, b) => a.localeCompare(b));

  const handleRangeSelected = (params: { startDate: Date; endDate: Date }) => {
    const { startDate, endDate } = params;
    const tmpDate = startDate;
    const newArr: string[] = [];
    const delArr: string[] = [];

    setDays(prevDays => {
      if (tmpDate.getTime() === endDate.getTime()) {
        const stringDate = moment(tmpDate).format('YYYY-MM-DD');
        if (prevDays.includes(stringDate)) {
          return prevDays.filter(d => d !== stringDate);
        }
        return [...prevDays, stringDate];
      }
      do {
        const stringDate = moment(tmpDate).format('YYYY-MM-DD');
        if (!prevDays.includes(stringDate)) {
          newArr.push(stringDate);
        } else {
          delArr.push(stringDate);
        }
        tmpDate.setDate(tmpDate.getDate() + 1);
      } while (tmpDate.getTime() <= endDate.getTime());
      return [...prevDays.filter(v => !delArr.includes(v)), ...newArr];
    });
  };

  const handleCopyYears = (selectedYear: number) => {
    setDays(prevDays => {
      const valsToCopy = prevDays.filter(v => {
        const date = new Date(v);
        return date.getFullYear() === selectedYear;
      });
      const newVals = valsToCopy.map(v => {
        const date = new Date(v);
        date.setFullYear(currentYear);
        return moment(date).format('YYYY-MM-DD');
      });
      return [...prevDays, ...newVals];
    });
  };

  useEffect(() => {
    setFieldsValue({ days });
  }, [days, setFieldsValue]);

  useEffect(() => {
    setDays(data.days);
  }, [data.days]);

  return (
    <Form className='calendar-form'>
      <Row>
        <Row>
          <Form.Item label={<FormattedMessage id='calendars.detail.name' />}>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='validations.required' />,
                },
              ],
            })(<Input disabled={isView} />)}
          </Form.Item>
        </Row>
        <Row className='control-buttons'>
          {!isView && mode !== Mode.CREATE && (
            <CopyDates years={uniqueYears} onCopyYears={handleCopyYears} />
          )}
          {mode !== Mode.CREATE && (
            <Button
              type='primary'
              onClick={e => {
                e.preventDefault();
                setVisible(true);
              }}
            >
              <FormattedMessage id='calendars.detail.entity.button' />
            </Button>
          )}
        </Row>
      </Row>
      <Row>
        <Form.Item>
          <YearCalendar
            dataSource={days.map(v => {
              const date = new Date(v);
              return {
                startDate: date,
                endDate: date,
                color: '#ff0000',
              };
            })}
            enableRangeSelection={!isView}
            onRangeSelected={handleRangeSelected}
            onYearChanged={({ currentYear: year }) => setCurrentYear(year)}
          />
        </Form.Item>
      </Row>
      <Modal
        visible={isVisible}
        title={<FormattedMessage id='calendars.detail.entity.title' />}
        footer={null}
        onCancel={() => {
          setVisible(false);
          setCurrentYear(defaultYear);
        }}
      >
        <EntityTable dataSource={data.assignedTo} />
      </Modal>
    </Form>
  );
};

export default Form.create<Props>({
  mapPropsToFields: ({ data }) => transformFormData(data),
})(CalendarForm);
