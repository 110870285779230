import { Action, action } from 'easy-peasy';

export interface GeneralStore {
  collapsed: boolean;
  setCollapsed: Action<GeneralStore, boolean>;
}

const general: GeneralStore = {
  collapsed: false,
  setCollapsed: action((state, payload) => {
    state.collapsed = payload;
  }),
};

export default general;
