import React from 'react';
import { Table, Icon, Tooltip, Row } from 'antd';
import { useIntl } from 'react-intl';
import { useStoreState } from '../store/hooks';
import { WarehouseTransportListItem } from '../api/types';
import { flatDays } from '../utils/DataManagement';
import UpdateButton from './UpdateButton';

export interface Props {
  onView: (id: number) => void;
  onEdit: (id: number) => void;
}

const TransportDefinitionsTable: React.FC<Props> = ({ onEdit, onView }) => {
  const { list: data, isLoading } = useStoreState(
    state => state.transportDefinitions,
  );

  const { formatMessage } = useIntl();

  return (
    <Table<WarehouseTransportListItem>
      loading={isLoading}
      dataSource={data.valueSeq().toArray()}
      size='middle'
      rowKey='id'
      rowClassName={() => 'c-pointer'}
      onRow={({ id }) => ({
        onClick: e => {
          e.preventDefault();
          onView(id);
        },
      })}
      columns={[
        {
          dataIndex: 'id',
          title: formatMessage({ id: 'transport_definitions.table.titles.id' }),
          fixed: 'left',
        },
        {
          dataIndex: 'warehouse.name',
          title: formatMessage({
            id: 'transport_definitions.table.titles.warehouse',
          }),
        },
        {
          dataIndex: 'transport.name',
          title: formatMessage({
            id: 'transport_definitions.table.titles.transport',
          }),
        },
        {
          dataIndex: 'country.code',
          title: formatMessage({
            id: 'transport_definitions.table.titles.country',
          }),
        },
        {
          dataIndex: 'deliveryDays',
          title: formatMessage({
            id: 'transport_definitions.table.titles.delivery_days',
          }),
          render: (text, { deliveryDays }) => (
            <>{flatDays(deliveryDays, formatMessage)}</>
          ),
        },
        {
          dataIndex: 'expeditionDays',
          title: formatMessage({
            id: 'transport_definitions.table.titles.expedition_days',
          }),
          align: 'center',
          render: (text, { expeditionDays, zips }) => (
            <Tooltip
              title={
                <div>
                  {expeditionDays.map(({ day, closeTime }) => (
                    <Row key={day}>
                      {`${formatMessage({
                        id: `enums.days.${day}`,
                      })}: ${closeTime}`}
                    </Row>
                  ))}
                  {zips !== undefined &&
                    zips.some(zip => zip.expeditionDays !== undefined) && (
                      <Row>
                        {formatMessage({
                          id:
                            'transport_definitions.table.titles.zip_custom_close_times_defined',
                        })}
                      </Row>
                    )}
                </div>
              }
            >
              <Icon className='eye' type='eye' theme='twoTone' />
            </Tooltip>
          ),
        },
        {
          dataIndex: 'calendar.name',
          title: formatMessage({
            id: 'transport_definitions.table.titles.calendar',
          }),
        },
        {
          key: 'update-button',
          align: 'right',
          render: (text, { id }) => (
            <UpdateButton
              onClick={e => {
                e.preventDefault();
                onEdit(id);
              }}
            />
          ),
        },
      ]}
    />
  );
};

export default TransportDefinitionsTable;
