import { IntlFormatters } from 'react-intl';
import { Form } from 'antd';
import { Day } from '../api/types';

export const getAbbreviation = (value: string, length = 2) =>
  value.slice(0, length);

export function flatDays(
  days: Day[],
  formatMessage: IntlFormatters['formatMessage'],
  separator = ', ',
): string {
  let result = '';
  for (let i = 0; i < days.length; i++) {
    const day = formatMessage({ id: `enums.days.${days[i]}` });
    result += getAbbreviation(day) + (i === days.length - 1 ? '' : separator);
  }
  return result;
}

export function transformFormData(obj: any): object {
  return Object.keys(obj).reduce((acc, cv) => {
    return {
      ...acc,
      [cv]:
        typeof obj[cv] === 'object' && !Array.isArray(obj[cv])
          ? transformFormData(obj[cv])
          : Form.createFormField({
              value: obj[cv],
            }),
    };
  }, {});
}

export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitFor: number = 500,
) => {
  let timeout: NodeJS.Timeout;

  return (...args: Parameters<F>): Promise<ReturnType<F>> =>
    new Promise(resolve => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => resolve(func(...args)), waitFor);
    });
};
