import { Action, action, Thunk, thunk } from 'easy-peasy';
import { OrderedMap } from 'immutable';
import {
  WarehouseTransportListItem,
  WarehouseTransport,
  BaseEntity,
  Country,
} from '../api/types';
import { StoreModel } from './types';
import { warehouseTransportApi } from '../api';

export const defaultTD: WarehouseTransport = {
  id: 0,
  warehouse: {} as BaseEntity,
  transport: {} as BaseEntity,
  deliveryDays: [],
  expeditionDays: [],
  calendar: {} as BaseEntity,
  zips: [],
  pickupPoints: [],
  country: {} as Country,
};

export interface TransportDefinitionStore {
  isLoading: boolean;
  warehouseIds: number[];
  list: OrderedMap<number, WarehouseTransportListItem>;
  detail: WarehouseTransport;
  setLoading: Action<TransportDefinitionStore, boolean>;
  setWarehouseIds: Action<TransportDefinitionStore, number[]>;
  setList: Action<TransportDefinitionStore, WarehouseTransportListItem[]>;
  setDetail: Action<TransportDefinitionStore, WarehouseTransport>;
  fetchList: Thunk<TransportDefinitionStore, void, any, StoreModel>;
  fetchTD: Thunk<
    TransportDefinitionStore,
    number,
    any,
    StoreModel,
    Promise<boolean>
  >;
  createTD: Thunk<
    TransportDefinitionStore,
    WarehouseTransport,
    any,
    StoreModel,
    Promise<boolean>
  >;
  updateTD: Thunk<
    TransportDefinitionStore,
    WarehouseTransport,
    any,
    StoreModel,
    Promise<boolean>
  >;
}

const transportDefinitions: TransportDefinitionStore = {
  isLoading: false,
  warehouseIds: [],
  list: OrderedMap(),
  detail: defaultTD,
  setLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setWarehouseIds: action((state, payload) => {
    state.warehouseIds = payload;
  }),
  setList: action((state, payload) => {
    state.list = OrderedMap(payload.map(v => [v.id, v]));
  }),
  setDetail: action((state, payload) => {
    state.detail = payload;
  }),
  fetchList: thunk(async (actions, payload, { getState }) => {
    try {
      actions.setLoading(true);
      const { warehouseIds } = getState();
      const { data } = await warehouseTransportApi.getWarehouseTransportList(
        warehouseIds,
      );
      actions.setList(data);
      actions.setLoading(false);
    } catch (error) {
      actions.setLoading(false);
    }
  }),
  fetchTD: thunk(async (actions, id) => {
    try {
      const { data } = await warehouseTransportApi.getWarehouseTransportDetail(
        id,
      );
      actions.setDetail(data);
    } catch (error) {
      return false;
    }
    return true;
  }),
  createTD: thunk(async (actions, value) => {
    try {
      await warehouseTransportApi.createWarehouseTransport(value);
      await actions.fetchList();
    } catch (error) {
      return false;
    }
    return true;
  }),
  updateTD: thunk(async (actions, value) => {
    try {
      await warehouseTransportApi.updateWarehouseTransport(value);
      await actions.fetchList();
    } catch (error) {
      return false;
    }
    return true;
  }),
};

export default transportDefinitions;
