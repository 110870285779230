import React, { forwardRef } from 'react';
import { Tag } from 'antd';
import { useIntl } from 'react-intl';
import { allDays, DayCheck } from '../api/types';

export interface Props {
  value?: DayCheck[];
  onChange?: Function;
  disabled?: boolean;
}

const DaysPicker = forwardRef<HTMLDivElement, Props>(
  ({ value, onChange, disabled }, ref) => {
    const { formatMessage } = useIntl();
    const handleChange = (checked: boolean, index: number) => {
      if (onChange) {
        if (value && value.length > 0) {
          const valueClone = [...value];
          valueClone[index].checked = checked;
          onChange(valueClone);
        }
      }
    };
    return (
      <div className='days-picker' ref={ref}>
        {allDays.map((val, index) => {
          const isChecked = value?.find(v => v.day === val)?.checked || false;
          return disabled ? (
            <Tag
              className='tag'
              key={val}
              color={isChecked ? '#1890ff' : undefined}
            >
              {formatMessage({ id: `enums.days.${val}` }).slice(0, 3)}
            </Tag>
          ) : (
            <Tag.CheckableTag
              className='tag'
              key={val}
              checked={isChecked}
              onChange={checked => {
                handleChange(checked, index);
              }}
            >
              {formatMessage({ id: `enums.days.${val}` }).slice(0, 3)}
            </Tag.CheckableTag>
          );
        })}
      </div>
    );
  },
);

export default DaysPicker;
