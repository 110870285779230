import calendar from './calendar';
import country from './country';
import pickupPoint from './pickupPoint';
import warehouse from './warehouse';
import warehouseTransport from './warehouseTransport';
import transport from './transport';
import user from './user';

export const calendarApi = calendar;
export const countryApi = country;
export const pickupPointApi = pickupPoint;
export const warehouseApi = warehouse;
export const warehouseTransportApi = warehouseTransport;
export const transportApi = transport;
export const userApi = user;
