import { createStore } from 'easy-peasy';
import user from './user';
import general from './general';
import warehouses from './warehouses';
import transportDefinitions from './transportDefinitions';
import pickupPoints from './pickupPoints';
import calendars from './calendars';
import countries from './countries';
import carriers from './carriers';
import { StoreModel } from './types';

export const store: StoreModel = {
  user,
  general,
  warehouses,
  transportDefinitions,
  pickupPoints,
  calendars,
  countries,
  carriers,
};

export default createStore(store);
