import React from 'react';
import PropTypes from 'prop-types';
import JsCalendar from 'js-year-calendar';
import 'js-year-calendar/dist/js-year-calendar.css';
import isEqualArray from '../utils/CompareArrays';

class Calendar extends React.Component {
  componentDidMount() {
    this.calendar = new JsCalendar(this.container, {
      // opsions
      allowOverlap: this.props.allowOverlap,
      alwaysHalfDay: this.props.alwaysHalfDay,
      contextMenuItems: this.props.contextMenuItems,
      customDayRenderer: this.props.customDayRenderer,
      customDataSourceRenderer: this.props.customDataSourceRenderer,
      dataSource: this.props.dataSource,
      disabledDays: this.props.disabledDays,
      disabledWeekDays: this.props.disabledWeekDays,
      displayDisabledDataSource: this.props.displayDisabledDataSource,
      displayHeader: this.props.displayHeader,
      displayWeekNumber: this.props.displayWeekNumber,
      enableContextMenu: this.props.enableContextMenu,
      enableRangeSelection: this.props.enableRangeSelection,
      hiddenWeekDays: this.props.hiddenWeekDays,
      language: this.props.language,
      loadingTemplate: this.props.loadingTemplate,
      maxDate: this.props.maxDate,
      minDate: this.props.minDate,
      roundRangeLimits: this.props.roundRangeLimits,
      style: this.props.style,
      weekStart: this.props.weekStart,
      startYear:
        this.props.year != null ? this.props.year : this.props.defaultYear,

      // Events
      clickDay: this.props.onDayClick,
      dayContextMenu: this.props.onDayContextMenu,
      mouseOnDay: this.props.onDayEnter,
      mouseOutDay: this.props.onDayLeave,
      renderEnd: this.props.onRenderEnd,
      selectRange: this.props.onRangeSelected,
      yearChanged: this.props.onYearChanged,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const cal = this.calendar;
    const ops = [];

    // opsions
    if (this.compare(this.props.allowOverlap, prevProps.allowOverlap))
      ops.push(() => cal.setAllowOverlap(this.props.allowOverlap));
    if (this.compare(this.props.alwaysHalfDay, prevProps.alwaysHalfDay))
      ops.push(() => cal.setAlwaysHalfDay(this.props.alwaysHalfDay, true));
    if (this.compare(this.props.contextMenuItems, prevProps.contextMenuItems))
      ops.push(() =>
        cal.setContextMenuItems(this.props.contextMenuItems, true),
      );
    if (this.compare(this.props.customDayRenderer, prevProps.customDayRenderer))
      ops.push(() =>
        cal.setCustomDayRenderer(this.props.customDayRenderer, true),
      );
    if (
      this.compare(
        this.props.customDataSourceRenderer,
        prevProps.customDataSourceRenderer,
      )
    )
      ops.push(() =>
        cal.setCustomDataSourceRenderer(
          this.props.customDataSourceRenderer,
          true,
        ),
      );
    if (!isEqualArray(this.props.dataSource, prevProps.dataSource)) {
      ops.push(() => cal.setDataSource(this.props.dataSource, true));
    }
    if (this.compare(this.props.disabledDays, prevProps.disabledDays))
      ops.push(() => cal.setDisabledDays(this.props.disabledDays, true));
    if (this.compare(this.props.disabledWeekDays, prevProps.disabledWeekDays))
      ops.push(() =>
        cal.setDisabledWeekDays(this.props.disabledWeekDays, true),
      );
    if (
      this.compare(
        this.props.displayDisabledDataSource,
        prevProps.displayDisabledDataSource,
      )
    )
      ops.push(() =>
        cal.setDisplayDisabledDataSource(
          this.props.displayDisabledDataSource,
          true,
        ),
      );
    if (this.compare(this.props.displayHeader, prevProps.displayHeader))
      ops.push(() => cal.setDisplayHeader(this.props.displayHeader, true));
    if (this.compare(this.props.displayWeekNumber, prevProps.displayWeekNumber))
      ops.push(() =>
        cal.setDisplayWeekNumber(this.props.displayWeekNumber, true),
      );
    if (this.compare(this.props.enableContextMenu, prevProps.enableContextMenu))
      ops.push(() =>
        cal.setEnableContextMenu(this.props.enableContextMenu, true),
      );
    if (
      this.compare(
        this.props.enableRangeSelection,
        prevProps.enableRangeSelection,
      )
    )
      ops.push(() =>
        cal.setEnableRangeSelection(this.props.enableRangeSelection, true),
      );
    if (this.compare(this.props.hiddenWeekDays, prevProps.hiddenWeekDays))
      ops.push(() => cal.setHiddenWeekDays(this.props.hiddenWeekDays, true));
    if (this.compare(this.props.language, prevProps.language))
      ops.push(() => cal.setLanguage(this.props.language, true));
    if (this.compare(this.props.loadingTemplate, prevProps.loadingTemplate))
      ops.push(() => cal.setLoadingTemplate(this.props.loadingTemplate, true));
    if (this.compare(this.props.maxDate, prevProps.maxDate))
      ops.push(() => cal.setMaxDate(this.props.maxDate, true));
    if (this.compare(this.props.minDate, prevProps.minDate))
      ops.push(() => cal.setMinDate(this.props.minDate, true));
    if (this.compare(this.props.roundRangeLimits, prevProps.roundRangeLimits))
      ops.push(() =>
        cal.setRoundRangeLimits(this.props.roundRangeLimits, true),
      );
    if (this.compare(this.props.style, prevProps.style))
      ops.push(() => cal.setStyle(this.props.style, true));
    if (this.compare(this.props.weekStart, prevProps.weekStart))
      ops.push(() => cal.setWeekStart(this.props.weekStart, true));
    if (this.compare(this.props.year, prevProps.year))
      ops.push(() => cal.setYear(this.props.year));

    // Events
    if (this.compare(this.props.onDayClick, prevProps.onDayClick))
      this.updateEvent('clickDay', prevProps.onDayClick, this.props.onDayClick);

    if (this.compare(this.props.onDayContextMenu, prevProps.onDayContextMenu))
      this.updateEvent(
        'dayContextMenu',
        prevProps.onDayContextMenu,
        this.props.onDayContextMenu,
      );
    if (this.compare(this.props.onDayEnter, prevProps.onDayEnter))
      this.updateEvent(
        'mouseOnDay',
        prevProps.onDayEnter,
        this.props.onDayEnter,
      );
    if (this.compare(this.props.onDayLeave, prevProps.onDayLeave))
      this.updateEvent(
        'mouseOutDay',
        prevProps.onDayLeave,
        this.props.onDayLeave,
      );
    if (this.compare(this.props.onRenderEnd, prevProps.onRenderEnd))
      this.updateEvent(
        'renderEnd',
        prevProps.onRenderEnd,
        this.props.onRenderEnd,
      );
    if (this.compare(this.props.onRangeSelected, prevProps.onRangeSelected))
      this.updateEvent(
        'selectRange',
        prevProps.onRangeSelected,
        this.props.onRangeSelected,
      );
    if (this.compare(this.props.onYearChanged, prevProps.onYearChanged))
      this.updateEvent(
        'yearChanged',
        prevProps.onYearChanged,
        this.props.onYearChanged,
      );

    if (ops.length > 0) {
      ops.forEach(op => op());

      // If the year has changed, the calendar has automatically been rendered
      cal.render();
    }
  }

  static locales = JsCalendar.locales; // Map the "locales" property to the js-year-calendar "locales" property, in order to make the locale files compatible

  static propsTypes = {
    // opsions
    allowOverlap: PropTypes.bool,
    alwaysHalfDay: PropTypes.bool,
    contextMenuItems: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        click: PropTypes.func,
        visible: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
        items: PropTypes.array,
      }),
    ),
    customDayRenderer: PropTypes.func,
    customDataSourceRenderer: PropTypes.func,
    dataSource: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          startDate: PropTypes.instanceOf(Date),
          endDate: PropTypes.instanceOf(Date),
          name: PropTypes.string,
        }),
      ),
      PropTypes.func,
    ]),
    disabledDays: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    disabledWeekDays: PropTypes.arrayOf(PropTypes.number),
    displayDisabledDataSource: PropTypes.bool,
    displayHeader: PropTypes.bool,
    displayWeekNumber: PropTypes.bool,
    enableContextMenu: PropTypes.bool,
    enableRangeSelection: PropTypes.bool,
    hiddenWeekDays: PropTypes.arrayOf(PropTypes.number),
    language: PropTypes.string,
    loadingTemplate: PropTypes.string,
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    roundRangeLimits: PropTypes.bool,
    selectRange: PropTypes.bool,
    style: PropTypes.string,
    weekStart: PropTypes.number,
    year: PropTypes.number,

    // Events
    onDayClick: PropTypes.func,
    onDayContextMenu: PropTypes.func,
    onDayEnter: PropTypes.func,
    onDayLeave: PropTypes.func,
    onRenderEnd: PropTypes.func,
    onSelectRange: PropTypes.func,
    onYearChanged: PropTypes.func,
  };

  compare(a, b) {
    if (typeof a === 'function' && typeof b === 'function') {
      return a.toString() !== b.toString();
    }
    if (a instanceof Date && b instanceof Date) {
      return a.getTime() !== b.getTime();
    }
    if (
      a !== null &&
      typeof a === 'object' &&
      b !== null &&
      typeof b === 'object'
    ) {
      const aKeys = Object.keys(a);
      const bKeys = Object.keys(a);

      if (aKeys.length !== bKeys.length) {
        return true;
      }
      return aKeys.some(key => this.compare(a[key], b[key]));
    }

    return a !== b;
  }

  updateEvent(eventName, oldListener, newListener) {
    this.container.removeEventListener(eventName, oldListener);
    this.container.addEventListener(eventName, newListener);
  }

  render() {
    return (
      <div
        ref={elt => {
          this.container = elt;
        }}
      />
    );
  }
}

export default Calendar;
