import React, { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Form, InputNumber, Icon } from 'antd';
import { Zip, DayCheckTime, ExpeditionDay, allDays, Day } from '../api/types';
import ZipCloseTimeInput from './ZipCloseTimeInput';

export interface Props {
  usesCustomCloseTimes: boolean;
  warehouseTransportExpeditionDays: DayCheckTime[];
  value?: Zip[];
  onChange?: Function;
  disabled?: boolean;
}

const ZipInputs = forwardRef<HTMLDivElement, Props>(
  (
    {
      usesCustomCloseTimes,
      warehouseTransportExpeditionDays,
      value,
      onChange,
      disabled,
    },
    ref,
  ) => {
    const zipFormItemLayout = {
      labelCol: { span: 12 },
      wrapperCol: { span: 12 },
    };

    const handleChange = (field: keyof Zip, index: number, val: any) => {
      if (onChange) {
        if (value && value.length > 0) {
          const valueClone = [...value];
          valueClone[index][field] = val;
          onChange(valueClone);
        }
      }
    };

    const handleRemove = (id: number) => {
      if (onChange && value) {
        onChange(value.filter(v => v.id !== id));
      }
    };

    return (
      <div ref={ref}>
        {value
          ? value.map(
              (
                { id, zipFrom, zipTo, deliveryDuration, expeditionDays },
                index,
              ) => {
                const dayCheckTime: DayCheckTime[] =
                  expeditionDays === undefined || expeditionDays.length === 0
                    ? warehouseTransportExpeditionDays.map(wtexp => {
                        return { ...wtexp };
                      })
                    : allDays.map((v: Day) => {
                        const ind = warehouseTransportExpeditionDays.findIndex(
                          val => val.day === v,
                        );
                        const isChecked =
                          warehouseTransportExpeditionDays[ind].checked;
                        const expeditionDay = expeditionDays.find(
                          expDay => expDay.day === (ind + 1).toString(),
                        );
                        const closeTime =
                          expeditionDay !== undefined
                            ? expeditionDay.closeTime
                            : 'x';

                        return {
                          checked: isChecked,
                          day: v,
                          closeTime: isChecked ? closeTime : 'x',
                        };
                      });

                return (
                  <div key={id}>
                    <Row type='flex' align='middle' justify='space-around'>
                      <Col span={6}>
                        <Form.Item
                          label={
                            <FormattedMessage id='transport_definitions.detail.zip_range.zip_from' />
                          }
                          {...zipFormItemLayout}
                        >
                          <InputNumber
                            disabled={disabled}
                            value={zipFrom}
                            onChange={val =>
                              handleChange('zipFrom', index, val)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label={
                            <FormattedMessage id='transport_definitions.detail.zip_range.zip_to' />
                          }
                          {...zipFormItemLayout}
                        >
                          <InputNumber
                            disabled={disabled}
                            value={zipTo}
                            onChange={val => handleChange('zipTo', index, val)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item
                          style={{ position: 'relative', left: '-18px' }}
                          label={
                            <FormattedMessage id='transport_definitions.detail.zip_range.delivery_duration' />
                          }
                          {...zipFormItemLayout}
                        >
                          <InputNumber
                            disabled={disabled}
                            value={deliveryDuration}
                            onChange={val =>
                              handleChange('deliveryDuration', index, val)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        {!disabled && (
                          <Icon
                            className='delete-icon'
                            onClick={e => {
                              e.preventDefault();
                              handleRemove(id);
                            }}
                            type='delete'
                          />
                        )}
                      </Col>
                    </Row>
                    {usesCustomCloseTimes && (
                      <ZipCloseTimeInput
                        value={dayCheckTime}
                        zipCloseTimes={expeditionDays}
                        disabled={disabled}
                        onChange={(val: ExpeditionDay[] | undefined) =>
                          handleChange('expeditionDays', index, val)
                        }
                      />
                    )}
                  </div>
                );
              },
            )
          : null}
      </div>
    );
  },
);

export default ZipInputs;
