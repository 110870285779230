import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import TransportDefinitionsPage from '../pages/TransportDefinitionsPage';
import PickupPointsPage from '../pages/PickupPointsPage';
import CalendarPage from '../pages/CalendarPage';
import CarrierPage from '../pages/CarrierPage';
import CountryPage from '../pages/CountryPage';
import WarehousePage from '../pages/WarehousePage';
import { useStoreState } from '../store/hooks';
import PrivateRoute from './PrivateRoute';
import LoginPage from '../pages/LoginPage';
import LogoutPage from '../pages/LogoutPage';

export const INDEX = '/';
export const LOGIN = '/login';
export const CALENDARS = '/calendars';
export const CARRIERS = '/carriers';
export const COUNTRIES = '/countries';
export const WAREHOUSES = '/warehouses';
export const TRANSPORT_DEFINITIONS = '/transport-definitions';
export const PUP_DEFINITIONS = '/pickup-points-definitions';
export const LOGOUT = '/logout';

export const getHomeLink = (isLoggedIn: boolean) =>
  isLoggedIn ? TRANSPORT_DEFINITIONS : LOGIN;

const Routes: React.FC = () => {
  const isLoggedIn = useStoreState(state => state.user.isLoggedIn);

  return (
    <Switch>
      <Redirect push exact from={INDEX} to={getHomeLink(isLoggedIn)} />
      {!isLoggedIn && <Route path={LOGIN} component={LoginPage} />}
      <PrivateRoute
        path={TRANSPORT_DEFINITIONS}
        component={TransportDefinitionsPage}
      />
      <PrivateRoute path={PUP_DEFINITIONS} component={PickupPointsPage} />
      <PrivateRoute path={CALENDARS} component={CalendarPage} />
      <PrivateRoute path={WAREHOUSES} component={WarehousePage} />
      <PrivateRoute path={CARRIERS} component={CarrierPage} />
      <PrivateRoute path={COUNTRIES} component={CountryPage} />
      <PrivateRoute path={LOGOUT} component={LogoutPage} />
      <Redirect to={INDEX} push />
    </Switch>
  );
};

export default Routes;
