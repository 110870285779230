import React, { useEffect, useState } from 'react';
import { Modal, message } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import NewButton from '../components/NewButton';
import { useStoreActions, useStoreState } from '../store/hooks';
import CarrierTable from '../components/CarrierTable';
import { TransportDetail } from '../api/types';
import CarrierForm from '../components/CarrierForm';
import { Mode } from '../store/types';
import useFormRef from '../hooks/useFormRef';
import { defaultCarrier } from '../store/carriers';

const CarrierPage: React.FC = () => {
  const [isVisible, setVisible] = useState(false);
  const [mode, setMode] = useState<Mode>(Mode.VIEW);
  const detail = useStoreState(state => state.carriers.detail);
  const {
    fetchList,
    createCarrier,
    updateCarrier,
    fetchCarrier,
    setDetail,
  } = useStoreActions(actions => actions.carriers);

  const { saveFormRef, formRef } = useFormRef<TransportDetail>();

  const { formatMessage } = useIntl();

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const handleEdit = async (id: number) => {
    await fetchCarrier(id);
    setMode(Mode.EDIT);
    setVisible(true);
  };

  const handleView = async (id: number) => {
    await fetchCarrier(id);
    setMode(Mode.VIEW);
    setVisible(true);
  };

  const handleNew = () => {
    setDetail(defaultCarrier);
    setMode(Mode.CREATE);
    setVisible(true);
  };

  const handleSave = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    formRef!.validateFields((err, values) => {
      if (!err) {
        const inValues = {
          ...values,
        };
        switch (mode) {
          case Mode.CREATE:
            createCarrier(inValues).then(res => {
              if (res) {
                message.success(
                  formatMessage({ id: 'common.message.success_new' }),
                );
                setVisible(false);
              }
            });
            break;
          case Mode.EDIT:
            updateCarrier(inValues).then(res => {
              if (res) {
                message.success(
                  formatMessage({ id: 'common.message.success_edit' }),
                );
                setVisible(false);
              }
            });
            break;
          default:
            break;
        }
      }
    });
  };

  return (
    <div className='carriers'>
      <NewButton onClick={handleNew} />
      <CarrierTable onEdit={handleEdit} onView={handleView} />
      <Modal
        title={<FormattedMessage id='carriers.detail.title' />}
        visible={isVisible}
        footer={mode === Mode.VIEW ? null : undefined}
        onCancel={e => {
          e.preventDefault();
          setVisible(false);
          formRef!.resetFields();
        }}
        okText={<FormattedMessage id='common.save' />}
        onOk={handleSave}
      >
        <CarrierForm ref={saveFormRef} data={detail} mode={mode} />
      </Modal>
    </div>
  );
};

export default CarrierPage;
