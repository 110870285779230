import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Spin } from 'antd';
import { useStoreState, useStoreActions } from '../store/hooks';
import { INDEX } from '../routes/Routes';

export interface Props {}

const LogoutPage: React.FC<Props> = () => {
  const isLoggedIn = useStoreState(state => state.user.isLoggedIn);
  const logout = useStoreActions(actions => actions.user.logout);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (isLoggedIn) {
      logout();
    }
  }, [isLoggedIn, logout]);

  return isLoggedIn ? (
    <div className='logout'>
      <Spin tip={formatMessage({ id: 'logout' })} />
    </div>
  ) : (
    <Redirect to={INDEX} push />
  );
};
export default LogoutPage;
