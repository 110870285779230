import React from 'react';
import { Table } from 'antd';
import { useIntl } from 'react-intl';
import { useStoreState } from '../store/hooks';
import { Warehouse } from '../api/types';
import { flatDays } from '../utils/DataManagement';
import UpdateButton from './UpdateButton';

export interface Props {
  onView: (id: number) => void;
  onEdit: (id: number) => void;
}

const WarehouseTable: React.FC<Props> = ({ onEdit, onView }) => {
  const { list: data, isLoading } = useStoreState(state => state.warehouses);

  const { formatMessage } = useIntl();

  return (
    <Table<Warehouse>
      loading={isLoading}
      dataSource={data.valueSeq().toArray()}
      size='middle'
      rowKey='id'
      rowClassName={record =>
        `c-pointer${!record.active ? ' inactive-row' : ''}`
      }
      onRow={({ id }) => ({
        onClick: e => {
          e.preventDefault();
          onView(id);
        },
      })}
      columns={[
        {
          dataIndex: 'id',
          title: formatMessage({ id: 'warehouses.table.titles.id' }),
        },
        {
          dataIndex: 'name',
          title: formatMessage({
            id: 'warehouses.table.titles.name',
          }),
          render: (text, { name, active }) =>
            active
              ? name
              : `${name} <${formatMessage({ id: 'common.inactive' })}>`,
        },
        {
          dataIndex: 'country.code',
          title: formatMessage({
            id: 'warehouses.table.titles.country',
          }),
        },
        {
          dataIndex: 'operationDays',
          title: formatMessage({
            id: 'warehouses.table.titles.operation_days',
          }),
          render: (text, { operationDays }) => (
            <>{flatDays(operationDays, formatMessage)}</>
          ),
        },
        {
          dataIndex: 'calendar.name',
          title: formatMessage({
            id: 'warehouses.table.titles.calendar',
          }),
        },
        {
          dataIndex: 'stockingDelay',
          title: formatMessage({
            id: 'warehouses.table.titles.stocking_delay',
          }),
          align: 'center',
        },
        {
          dataIndex: 'expeditionDelay',
          title: formatMessage({
            id: 'warehouses.table.titles.expedition_delay',
          }),
          align: 'center',
        },
        {
          dataIndex: 'stockCloseTime',
          title: formatMessage({
            id: 'warehouses.table.titles.stock_close_time',
          }),
          align: 'center',
        },
        {
          key: 'update-button',
          align: 'right',
          render: (text, { id }) => (
            <UpdateButton
              onClick={e => {
                e.preventDefault();
                onEdit(id);
              }}
            />
          ),
        },
      ]}
    />
  );
};

export default WarehouseTable;
